import apiService from './apiService';

const authService = {
  login: async (credentials) => {
    try {
      const response = await apiService.post('/login', credentials);
      // console.log('Login Response:', response); // Логируем ответ сервера

      const { token, ...userData } = response.data;

      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(userData));

      return userData;
    } catch (error) {
      console.error('Login Error:', error.response || error); // Логируем ошибку
      throw error; // Пробрасываем для обработки в компоненте
    }
  },

  getCurrentUserFromToken: async () => {
    try {
      const response = await apiService.get('/auth/current-user');
      // console.log('Current User:', response.data); // Логируем текущего пользователя
      return response.data;
    } catch (error) {
      console.error('Get Current User Error:', error.response || error); // Логируем ошибку
      throw error;
    }
  },

  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  },

  getCurrentUser2: () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  },
};

export default authService;
