import React from 'react';
import { Card, Row, Col } from 'antd';


const CloserDashboard = () => {
  return (
    <div>
      <h1>Closer Dashboard</h1>
      <Row gutter={16}>
        <Col span={12}>
          <Card title="Lead Statistics" bordered={false}>
            Статистика лидов команды
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Employee Management" bordered={false}>
            Управление аккаунтами сотрудников
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CloserDashboard;
