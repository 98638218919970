import React, { useState, useEffect } from 'react';
import { Upload, Button, message, Form, Select, Spin, Modal, Typography, Result } from 'antd';
import { UploadOutlined, CheckCircleOutlined, CloseCircleOutlined, FileOutlined } from '@ant-design/icons';
import { getUsers, getTeams, importLeads, checkTaskStatus } from '../../services/apiMethods'; 

const { Option } = Select;
const { Paragraph } = Typography;

const LeadImport = () => {
  const [responsibles, setResponsibles] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedResponsible, setSelectedResponsible] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [taskId, setTaskId] = useState(null);
  const [status, setStatus] = useState(null);
  const [importStats, setImportStats] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImportInProgress, setIsImportInProgress] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = await getUsers();
        const teams = await getTeams();
        setResponsibles(users);
        setTeams(teams);
      } catch (error) {
        message.error('Ошибка загрузки данных.');
      }
    };
    fetchData();
  }, []);

  const handleFileChange = (info) => {
    const file = info.file.originFileObj || info.file;
    if (file) {
      setSelectedFile(file);
      message.success(`Файл ${file.name} выбран.`);
    } else {
      message.error('Ошибка при выборе файла.');
    }
  };

  const removeFile = () => {
    setSelectedFile(null);
    message.info('Файл удален.');
  };

  const resetForm = () => {
    setSelectedResponsible(null);
    setSelectedTeam(null);
    setSelectedFile(null);
    setStatus(null);
    setImportStats(null);
    setTaskId(null);
    setIsImportInProgress(false);
  };

  const showConfirmationModal = () => {
    if (!selectedFile) {
      message.error('Пожалуйста, выберите файл перед началом импорта.');
      return;
    }
    setIsModalOpen(true);
  };

  const handleConfirmImport = async () => {
    setIsModalOpen(false);
    setIsLoading(true); // Включаем спиннер
    setIsImportInProgress(true); // Указываем, что импорт начат
    setImportStats(null);
  
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('assignedTo', selectedResponsible || 'Unassigned');
    formData.append('team', selectedTeam || null);
  
    try {
      const response = await importLeads(formData);
      setTaskId(response.taskId);
      message.success('Импорт начат.');
      checkTaskProgress(response.taskId); // Отслеживание статуса задачи
    } catch (error) {
      message.error('Ошибка импорта.');
      setIsLoading(false); // Выключаем спиннер при ошибке
      setIsImportInProgress(false);
    }
  };
  
  const checkTaskProgress = async (taskId) => {
    try {
      const task = await checkTaskStatus(taskId);
      
      if (task.status === 'in-progress') {
        setTimeout(() => checkTaskProgress(taskId), 5000); // Продолжаем проверку каждые 5 секунд
      } else if (task.status === 'completed') {
        setImportStats(task.statistics);
        setStatus('success');
        message.success('Импорт завершён.');
        setIsImportInProgress(false); // Завершаем процесс импорта
        setIsLoading(false); // Останавливаем спиннер
      } else if (task.status === 'failed') {
        setStatus('error');
        message.error('Ошибка выполнения задачи.');
        setIsImportInProgress(false); // Завершаем процесс при ошибке
        setIsLoading(false); // Останавливаем спиннер
      }
    } catch (error) {
      message.error('Ошибка проверки статуса задачи.');
      setStatus('error');
      setIsImportInProgress(false); // Завершаем процесс при ошибке
      setIsLoading(false); // Останавливаем спиннер
    }
  };
  
  
  
  

  return (
    <div>
      <Form layout="vertical">
        <Form.Item label="Ответственный">
          <Select
            placeholder="Выберите ответственного"
            onChange={setSelectedResponsible}
            value={selectedResponsible}
            allowClear
          >
            {responsibles.map((user) => (
              <Option key={user.id} value={user.username}>
                {user.username}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Команда">
          <Select
            placeholder="Выберите команду"
            onChange={setSelectedTeam}
            value={selectedTeam}
            allowClear
          >
            {teams.map((team) => (
              <Option key={team.id} value={team.name}>
                {team.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Файл для импорта">
          <Upload
            beforeUpload={(file) => {
              handleFileChange({ file });
              return false;
            }}
            showUploadList={false}
          >
            <Button icon={<UploadOutlined />}>Выберите файл</Button>
          </Upload>
          {selectedFile && (
            <div style={{ marginTop: 10 }}>
              <Paragraph>
                <FileOutlined /> {selectedFile.name}
                <Button type="link" onClick={removeFile} danger>
                  Удалить
                </Button>
              </Paragraph>
            </div>
          )}
        </Form.Item>

        <Button type="primary" onClick={showConfirmationModal} disabled={isLoading}>
          Начать импорт
        </Button>
      </Form>

      {isImportInProgress && (
        <div style={{ marginTop: 20 }}>
          <Spin size="large" /> <p>Импорт выполняется, пожалуйста, подождите...</p>
        </div>
      )}

      {status && (
        <div style={{ marginTop: 20 }}>
          {status === 'success' ? (
            <Result
              status="success"
              title="Импорт завершён успешно!"
              extra={[
                <Button type="primary" onClick={resetForm}>
                  Сбросить форму
                </Button>,
              ]}
            />
          ) : (
            <Result
              status="error"
              title="Ошибка импорта"
              extra={[
                <Button type="primary" onClick={resetForm}>
                  Сбросить форму
                </Button>,
              ]}
            />
          )}
        </div>
      )}

      {importStats && (
        <div style={{ marginTop: 20, padding: 15, border: '1px solid #d9d9d9', borderRadius: 4 }}>
          <h3>Статистика импорта:</h3>
          <Paragraph>
            <CheckCircleOutlined style={{ color: 'green', marginRight: 8 }} />
            Импортировано лидов: {importStats.imported}
          </Paragraph>
          <Paragraph>
            <CloseCircleOutlined style={{ color: 'orange', marginRight: 8 }} />
            Удалено дублей: {importStats.duplicates}
          </Paragraph>
          <Paragraph>
            <CloseCircleOutlined style={{ color: 'red', marginRight: 8 }} />
            Пропущено строк: {importStats.skipped}
          </Paragraph>
        </div>
      )}

      <Modal
        title="Подтвердите импорт"
        open={isModalOpen}
        onOk={handleConfirmImport}
        onCancel={() => setIsModalOpen(false)}
        okText="Да, начать"
        cancelText="Отмена"
      >
        <p>Вы уверены, что хотите начать импорт?</p>
      </Modal>
    </div>
  );
};

export default LeadImport;
