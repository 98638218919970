import React, { useState, useEffect } from 'react';
import UserForm from '../components/Admin/UserForm';
import UserTable from '../components/Admin/UserTable';
import { Row, Col, message, Card } from 'antd';
import { getUsers, addUser, deleteUser } from '../services/apiMethods'; // Используем API методы

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Флаг загрузки данных

  useEffect(() => {
    fetchUsers(); // Загрузка пользователей при монтировании компонента
  }, []);

  const fetchUsers = async () => {
    try {
      setIsLoading(true); // Устанавливаем флаг загрузки
      const response = await getUsers(); // Без параметров, просто получаем всех пользователей
      console.log('Полученные пользователи:', response); // Проверяем формат данных
      setUsers(response); // Устанавливаем пользователей
    } catch (error) {
      console.error('Ошибка при загрузке пользователей:', error);
      message.error('Ошибка при загрузке пользователей');
    } finally {
      setIsLoading(false); // Снимаем флаг загрузки
    }
  };

  const handleAddUser = async (newUser) => {
    try {
      await addUser(newUser); // Добавляем нового пользователя
      message.success('Пользователь успешно добавлен');
      fetchUsers(); // Перезагрузка списка пользователей
    } catch (error) {
      console.error('Ошибка при добавлении пользователя:', error);
      message.error('Ошибка при добавлении пользователя');
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      await deleteUser(id); // Удаляем пользователя
      message.success('Пользователь успешно удален');
      fetchUsers(); // Перезагрузка списка пользователей
    } catch (error) {
      console.error('Ошибка при удалении пользователя:', error);
      message.error('Ошибка при удалении пользователя');
    }
  };

  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
      <h1>Панель Администратора</h1>
      <Row gutter={16} style={{ marginTop: '40px' }} justify="center">
        <Col span={24}>
          <Card title="Добавить пользователя" style={{ marginBottom: '20px' }}>
            <UserForm onAddUser={handleAddUser} />
          </Card>
        </Col>
        <Col span={24}>
          <Card title="Список пользователей">
            <UserTable
              initialUsers={users} // Передаем список пользователей в таблицу
              onDeleteUser={handleDeleteUser} // Передаем обработчик удаления
              isLoading={isLoading} // Передаем флаг загрузки
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AdminPage;
