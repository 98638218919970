import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Select, DatePicker, Button, message, Spin } from 'antd';
import { getTeams, getTeamStatistics } from '../../services/apiMethods'; // Импорт новых методов
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

const statusMap = [
  { id: 1, name: "Новый" },
  { id: 2, name: "Автоответчик" },
  { id: 3, name: "НБТ" },
  { id: 4, name: "Нет средств" },
  { id: 5, name: "Умник" },
  { id: 6, name: "Срез" },
  { id: 7, name: "Перезвон" },
  { id: 8, name: "Поляк" },
  { id: 9, name: "Списали" },
  { id: 10, name: "Срез на закрытии" },
  { id: 11, name: "Закрыт" },
];

const TeamStatistics = () => {
  const [teamStats, setTeamStats] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [dateRange, setDateRange] = useState([moment().startOf('month'), moment().endOf('month')]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    try {
      const data = await getTeams();
      setTeams(data.map((team) => ({ id: team.id, name: team.name })));
    } catch {
      message.error('Ошибка при загрузке команд');
    }
  };

  const fetchTeamStatistics = async () => {
    if (!selectedTeam) {
      message.warning('Выберите команду для получения статистики');
      return;
    }

    setLoading(true);
    try {
      const [startDate, endDate] = dateRange;
      const data = await getTeamStatistics(
        selectedTeam,
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD')
      );

      setTeamStats(data.statusCounts || []);
    } catch {
      message.error('Ошибка при загрузке статистики команды');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Статистика по командам</h2>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={8}>
          <Select
            placeholder="Выберите команду"
            value={selectedTeam}
            onChange={setSelectedTeam}
            style={{ width: '100%' }}
          >
            {teams.map((team) => (
              <Option key={team.id} value={team.name}>
                {team.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={8}>
          <RangePicker
            value={dateRange}
            onChange={(dates) => setDateRange(dates)}
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={8}>
          <Button
            type="primary"
            onClick={fetchTeamStatistics}
            style={{ width: '100%' }}
            loading={loading}
          >
            Получить статистику
          </Button>
        </Col>
      </Row>
      <Row gutter={16}>
        {loading ? (
          <Spin />
        ) : teamStats.length > 0 ? (
          teamStats.map((stat, index) => {
            const statusName = statusMap.find((s) => s.name === stat.status)?.name || 'Неизвестный';
            return (
              <Col span={8} key={index}>
                <Card>
                  <p>Статус: {statusName}</p>
                  <p>Количество: {stat.count}</p>
                </Card>
              </Col>
            );
          })
        ) : (
          <p>Нет данных для отображения статистики</p>
        )}
      </Row>
    </div>
  );
};

export default TeamStatistics;
