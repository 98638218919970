import React from 'react';
import CloserDashboard from '../components/Closer/CloserDashboard';

import LeadList from '../components/Shared/LeadList';

const CloserPage = ({ user }) => {
  return (
    <div>
      <CloserDashboard />
      <LeadList user={user} />
    </div>
  );
};

export default CloserPage;
