import React, { useState, useEffect } from 'react';
import { DatePicker, Card, Statistic, Row, Col, Select, message, List } from 'antd';
import { Pie } from 'react-chartjs-2';
import moment from 'moment';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { getUsers, getUserStatistics } from '../../services/apiMethods'; // Импорты методов API

ChartJS.register(ArcElement, Tooltip, Legend);

const { Option } = Select;

const LeadStatistics = () => {
  const [statistics, setStatistics] = useState({ statusCounts: [], totalLeads: 0 });
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment().endOf('month'));
  const [user, setUser] = useState('');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (user) fetchStatistics();
  }, [startDate, endDate, user]);

  const fetchUsers = async () => {
    try {
      const responsibles = await getUsers();
      setUsers(responsibles.map(user => ({ username: user.username, id: user.id })));
      setUser(responsibles[0]?.username || "");
    } catch (error) {
      message.error('Ошибка при загрузке списка пользователей');
    }
  };

  const fetchStatistics = async () => {
    setLoading(true);
    try {
      const stats = await getUserStatistics({
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        username: user,
      });
      setStatistics(stats);
    } catch (error) {
      message.error('Ошибка при загрузке статистики пользователя');
    } finally {
      setLoading(false);
    }
  };

  const data = {
    labels: statistics.statusCounts.map((item) => item.status || 'Неизвестный статус'),
    datasets: [
      {
        data: statistics.statusCounts.map((item) => item.count),
        backgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#AAFF56', '#FF9AA2',
          '#B39CD0', '#FDFD96', '#77DD77', '#AEC6CF', '#CFCFC4',
          '#FFB347',
        ],
      },
    ],
  };

  return (
    <div>
      <h2>Статистика по лидам пользователя</h2>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={8}>
          <Select value={user} onChange={setUser} style={{ width: '100%' }}>
            {users.map(user => (
              <Option key={user.id} value={user.username}>{user.username}</Option>
            ))}
          </Select>
        </Col>
        <Col span={8}>
          <DatePicker value={startDate} onChange={setStartDate} style={{ width: '100%' }} />
        </Col>
        <Col span={8}>
          <DatePicker value={endDate} onChange={setEndDate} style={{ width: '100%' }} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Card title="Общая статистика">
            <Statistic title="Всего лидов" value={statistics.totalLeads} />
            <List
              header={<b>Подробная статистика по статусам:</b>}
              dataSource={statistics.statusCounts}
              renderItem={(item) => (
                <List.Item>
                  {item.status}: {item.count}
                </List.Item>
              )}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Pie data={data} />
        </Col>
      </Row>
    </div>
  );
};

export default LeadStatistics;
