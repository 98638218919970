import React from 'react';
import { Layout, Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  DashboardOutlined,
  FileTextOutlined,
  TeamOutlined,
  ImportOutlined,
  SettingOutlined,
  LogoutOutlined,
  UserOutlined
} from '@ant-design/icons';
import logo from './images/logo.svg';

const { Header } = Layout;

const AppHeader = ({ user, onLogout }) => {
  const navigate = useNavigate();
  const location = useLocation(); // Для подсветки активной ссылки

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  const handleMainClick = () => {
    if (user?.role === 'admin') {
      navigate('/admin');
    } else if (user?.role === 'closer') {
      navigate('/closer');
    } else if (user?.role === 'employee') {
      navigate('/employee');
    }
  };

  // Определяем текущую активную ссылку на основе маршрута
  const getSelectedKey = () => {
    if (user?.role === 'admin') {
      if (location.pathname.startsWith('/admin')) return ['admin'];
    } else if (user?.role === 'closer') {
      if (location.pathname.startsWith('/closer')) return ['closer'];
    } else if (user?.role === 'employee') {
      if (location.pathname.startsWith('/employee')) return ['employee'];
    }
    return [];
  };

  const menuItems = [
    {
      label: 'Главная',
      key: 'home',
      icon: <DashboardOutlined />,
      onClick: handleMainClick
    },
    ...(user?.role === 'admin' ? [
      {
        label: 'Список Лидов',
        key: 'lead-list',
        icon: <FileTextOutlined />,
        onClick: () => navigate('/lead-list')
      },
      {
        label: 'Статистика',
        key: 'statistics',
        icon: <TeamOutlined />,
        children: [
          { label: 'Статистика Команд', key: 'team-stat', icon: <TeamOutlined />, onClick: () => navigate('/team-stat') },
          { label: 'Статистика Лидов', key: 'lead-stat', icon: <FileTextOutlined />, onClick: () => navigate('/lead-stat') }
        ]
      },
      {
        label: 'Управление',
        key: 'admin-management',
        icon: <SettingOutlined />,
        children: [
          { label: 'IP Whitelist', key: 'whitelist', icon: <SettingOutlined />, onClick: () => navigate('/admin/ip-whitelist') },
          { label: 'Управление Командами', key: 'team-management', icon: <TeamOutlined />, onClick: () => navigate('/team-management') },
          { label: 'Импорт Лидов', key: 'lead-import', icon: <ImportOutlined />, onClick: () => navigate('/admin/lead-import') }
        ]
      },
    ] : []),
    ...(user?.role === 'closer' ? [
      { label: 'Панель Клоузера', key: 'closer', icon: <TeamOutlined />, onClick: () => navigate('/closer') }
    ] : []),
    ...(user?.role === 'employee' ? [
      { label: 'Панель Сотрудника', key: 'employee', icon: <TeamOutlined />, onClick: () => navigate('/employee') }
    ] : []),
    {
      label: 'Профиль',
      key: 'profile',
      icon: <UserOutlined />,
      onClick: () => navigate('/profile')
    },
    {
      label: 'Выйти',
      key: 'logout',
      icon: <LogoutOutlined />,
      onClick: handleLogout
    }
  ];

  return (
    <Header style={{ padding: '0 20px', backgroundColor: '#001529' }}>
      <div className="logo" style={{ float: 'left', color: 'white', fontSize: '18px', marginRight: '20px' }}>
        <img src={logo} alt="Logo" style={{ width: '80px', marginTop: '4px' }} />
      </div>

      <Menu
        theme="dark"
        mode="horizontal"
        selectedKeys={getSelectedKey()}
        defaultSelectedKeys={['home']}
        style={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}
        items={menuItems}
      />
    </Header>
  );
};

export default AppHeader;
