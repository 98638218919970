// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Layout, message } from 'antd';
import AdminPage from './pages/AdminPage';
import CloserPage from './pages/CloserPage';
import EmployeePage from './pages/EmployeePage';
import Login from './components/Shared/Login';
import ProtectedRoute from './components/Shared/ProtectedRoute';
import AppHeader from './components/Shared/Header';

import LeadList from './components/Shared/LeadList';
import LeadStatistics from './components/Admin/LeadStatistics';
import TeamStatistics from './components/Admin/TeamStatistics';
import WhitelistManagement from './components/Admin/WhitelistManagement';
import TeamManagement from './components/Admin/TeamManagement';
import LeadImport from './components/Admin/LeadImport';

import UserProfile from './components/Shared/UserProfile';
import authService from './services/authService';

const { Content, Footer } = Layout;

const App = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  // Проверка и загрузка текущего пользователя
  const fetchUser = async () => {
    try {
      const response = await authService.getCurrentUserFromToken();
      setUser(response); // Устанавливаем данные пользователя
    } catch (error) {
      message.error('Ошибка авторизации, выполните повторный вход.');
      authService.logout();
      navigate('/login');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUser();
    } else {
      setIsLoading(false);
    }
  }, []);

  const onLogout = () => {
    authService.logout();
    setUser(null);
    navigate('/login');
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout className="layout">
      {location.pathname !== '/login' && <AppHeader user={user} onLogout={onLogout} />}
      <Content style={{ padding: '50px 50px' }}>
        <Routes>
          <Route
            path="/login"
            element={<Login onLogin={(userData) => setUser(userData)} />}
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute isAllowed={user?.role === 'admin'}>
                <AdminPage user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/lead-list"
            element={
              <ProtectedRoute isAllowed={user?.role === 'admin' || user?.role === 'closer'}>
                <LeadList user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/lead-stat"
            element={
              <ProtectedRoute isAllowed={user?.role === 'admin' || user?.role === 'closer'}>
                <LeadStatistics user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/team-stat"
            element={
              <ProtectedRoute isAllowed={user?.role === 'admin' || user?.role === 'closer'}>
                <TeamStatistics user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/ip-whitelist"
            element={
              <ProtectedRoute isAllowed={user?.role === 'admin'}>
                <WhitelistManagement user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/team-management"
            element={
              <ProtectedRoute isAllowed={user?.role === 'admin' || user?.role === 'closer'}>
                <TeamManagement user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/lead-import"
            element={
              <ProtectedRoute isAllowed={user?.role === 'admin'}>
                <LeadImport user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/closer"
            element={
              <ProtectedRoute isAllowed={user?.role === 'closer'}>
                <CloserPage user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/employee"
            element={
              <ProtectedRoute isAllowed={user?.role === 'employee'}>
                <EmployeePage user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute isAllowed={['admin', 'closer', 'employee'].includes(user?.role)}>
                <UserProfile user={user} />
              </ProtectedRoute>
            }
          />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </Content>
      <Footer style={{ textAlign: 'center' }}>MateCRM ©2024</Footer>
    </Layout>
  );
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
