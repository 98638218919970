import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  message,
  Switch,
} from "antd";
import moment from "moment";
import {
  getLeads,
  getNextLead,
  updateLead,
  updateLeadActivity,
  getUsers,
  getTeams,
} from "../../services/apiMethods";

const { Option } = Select;

const statusMap = [
  { id: 1, name: "Новый" },
  { id: 2, name: "Автоответчик" },
  { id: 3, name: "НБТ" },
  { id: 4, name: "Нет средств" },
  { id: 5, name: "Умник" },
  { id: 6, name: "Срез" },
  { id: 7, name: "Перезвон" },
  { id: 8, name: "Поляк" },
  { id: 9, name: "Списали" },
  { id: 10, name: "Срез на закрытии" },
  { id: 11, name: "Закрыт" },
];

const LeadList = ({ user }) => {
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentLead, setCurrentLead] = useState(null);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchLeads();
    fetchTeamsData();
    fetchEmployeeOptions();
  }, [user]);

  const fetchLeads = async () => {
    setIsLoading(true);
    try {
      const data = await getLeads({
        role: user.role,
        assignedTo: user.role === "employee" ? user.username : undefined,
      });
      setLeads(data);
    } catch {
      message.error("Ошибка при загрузке лидов");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTeamsData = async () => {
    try {
      const teams = await getTeams();
      setTeamOptions(teams);
    } catch {
      message.error("Ошибка при загрузке команд");
    }
  };

  const fetchEmployeeOptions = async () => {
    try {
      const users = await getUsers({ role: "employee" });
      setEmployeeOptions(users);
    } catch {
      message.error("Ошибка при загрузке сотрудников");
    }
  };

  const fetchNextLead = async () => {
    try {
      const data = await getNextLead(user.username);
      if (data) {
        message.success("Назначен новый лид.");
        setLeads((prevLeads) => [...prevLeads, data]);
      } else {
        message.info("Нет доступных новых лидов.");
      }
    } catch {
      message.error("Ошибка при получении нового лида.");
    }
  };

  const handleUpdate = async (values) => {
    if (isSubmitting) return; // Предотвращение повторного нажатия
    setIsSubmitting(true); // Блокируем повторные запросы
  
    try {
      await updateLead(currentLead.id, { ...values, statusId: values.status });
      message.success("Статус лида успешно обновлен.");
      
      if (currentLead.statusId === 1 && values.status !== 1 && user.role === "employee") {
        await fetchNextLead();
      }
      
      fetchLeads();
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      if (error.response?.data.error === 'Нельзя вернуть лид к статусу "Новый"') {
        message.error("Нельзя вернуть лид к статусу 'Новый'.");
      } else {
        message.error("Ошибка при обновлении лида");
      }
    } finally {
      setIsSubmitting(false); // Разблокируем кнопку после завершения
    }
  };
  

  const showEditModal = (lead) => {
    setCurrentLead(lead);
    form.setFieldsValue({
      ...lead,
      status: lead.statusId,
      dateOfBirth: lead.dateOfBirth ? moment(lead.dateOfBirth) : null,
    });
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleLeadActivityChange = async (leadId, newStatus) => {
    setIsLoading(true);
    try {
      await updateLeadActivity(leadId, newStatus);
      fetchLeads();
      message.success("Статус активности лида обновлен.");
    } catch (error) {
      message.error("Ошибка при изменении статуса активности.");
    } finally {
      setIsLoading(false);
    }
  };

  const getColumnSelectProps = (dataIndex, options) => ({
    filters: options.map((option) => ({
      text: option.name || option.username || option,
      value: option.id || option.username || option,
    })),
    onFilter: (value, record) => record[dataIndex] === value,
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Поиск ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={confirm}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={confirm} style={{ width: "100%", marginBottom: 8 }}>
          Поиск
        </Button>
        <Button onClick={clearFilters} style={{ width: "100%" }}>
          Сброс
        </Button>
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });

  const columns = [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      render: (phone) => (
        <a href={`sip:${phone}`} target="_blank" rel="noopener noreferrer">
          {phone}
        </a>
      ),
      ...getColumnSearchProps("phone"),
    },
    {
      title: "Команда",
      dataIndex: "team",
      key: "team",
      ...getColumnSelectProps("team", teamOptions),
    },
    {
      title: "Статус",
      dataIndex: "statusId",
      key: "status",
      render: (statusId) => {
        const status = statusMap.find((status) => status.id === statusId);
        return status ? status.name : "Не указан";
      },
      ...getColumnSelectProps("statusId", statusMap),
    },
    {
      title: "Активность",
      dataIndex: "isActive",
      key: "isActive",
      filters: [
        { text: "Активен", value: true },
        { text: "Неактивен", value: false },
      ],
      onFilter: (value, record) => record.isActive === value,
      render: (isActive, record) => (
        <Switch
          checked={isActive}
          onChange={(checked) => handleLeadActivityChange(record.id, checked)}
        />
      ),
    },
    {
      title: "Дата обновления",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => moment(text).format("DD.MM.YYYY HH:mm"),
    },
    {
      title: "Комментарий",
      dataIndex: "comment",
      key: "comment",
      ...getColumnSearchProps("comment"),
    },
    {
      title: "Действия",
      key: "actions",
      render: (_, record) => (
        <Button onClick={() => showEditModal(record)}>Редактировать</Button>
      ),
    },
  ];

  return (
    <div>
      <h2>Лиды</h2>
      <Table
        dataSource={leads}
        columns={columns}
        rowKey="id"
        loading={isLoading}
        pagination={{ pageSize: 10 }}
      />
      <Modal
        title="Редактировать Лид"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout="vertical" form={form} onFinish={handleUpdate}>
          <Form.Item name="name" label="Имя" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="phone" label="Телефон" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="status" label="Статус">
            <Select>
              {statusMap.map((status) => (
                <Option key={status.id} value={status.id}>
                  {status.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {user.role !== "employee" && (
            <>
              <Form.Item name="team" label="Команда">
                <Select>
                  {teamOptions.map((team) => (
                    <Option key={team.id} value={team.id}>
                      {team.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="assignedTo" label="Ответственный сотрудник">
                <Select
                  showSearch
                  placeholder="Выберите или введите имя"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {employeeOptions.map((employee) => (
                    <Option key={employee.username} value={employee.username}>
                      {employee.username}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
          <Form.Item name="dateOfBirth" label="Дата рождения">
            <DatePicker format="DD.MM.YYYY" />
          </Form.Item>
          <Form.Item name="app" label="АПП">
            <Input />
          </Form.Item>
          <Form.Item name="bank" label="БАНК">
            <Input />
          </Form.Item>
          <Form.Item name="operation" label="ОПЕРАЦИЯ">
            <Input />
          </Form.Item>
          <Form.Item name="balance" label="БАЛАНС">
            <Input />
          </Form.Item>
          <Form.Item name="currency" label="ВАЛЮТКА">
            <Input />
          </Form.Item>
          <Form.Item name="comment" label="Комментарий">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Button type="primary" htmlType="submit" block loading={isSubmitting}>
  Сохранить
</Button>

        </Form>
      </Modal>
    </div>
  );
};

export default LeadList;
