import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  message,
  Select,
} from "antd";
import { 
  getTeams, 
  getUsersWithoutTeam, 
  getUsersInTeam, 
  updateTeam, 
  deleteTeam, 
  assignTeamToUser, 
  addNewTeam // новый метод для добавления команды
} from '../../services/apiMethods';

const { Option } = Select;

const TeamManagement = () => {
  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersWithoutTeam, setUsersWithoutTeam] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false); // для модалки создания команды
  const [currentTeam, setCurrentTeam] = useState(null);
  const [isUserListVisible, setIsUserListVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchTeams();
    fetchUsersWithoutTeam();
  }, []);

  const fetchTeams = async () => {
    try {
      const data = await getTeams();
      setTeams([
        ...data,
        { id: 'no-team', name: 'Без команды' }
      ]);
    } catch {
      message.error('Ошибка при загрузке команд');
    }
  };

  const fetchUsersWithoutTeam = async () => {
    try {
      const data = await getUsersWithoutTeam();
      setUsersWithoutTeam(data);
    } catch {
      message.error('Ошибка при загрузке пользователей без команды');
    }
  };

  const fetchUsersInTeam = async (teamId) => {
    try {
      const data = await getUsersInTeam(teamId);
      setUsers(data);
      setIsUserListVisible(true);
    } catch {
      message.error('Ошибка при загрузке пользователей команды');
    }
  };

  const handleUpdateTeam = async (id, values) => {
    try {
      await updateTeam(id, values);
      fetchTeams();
      message.success('Команда успешно обновлена');
      setIsModalVisible(false);
      setCurrentTeam(null);
    } catch {
      message.error('Ошибка при обновлении команды');
    }
  };

  const handleEditTeam = (team) => {
    setCurrentTeam(team);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentTeam(null);
  };

  const handleDeleteTeam = async (id) => {
    try {
      await deleteTeam(id);
      fetchTeams();
      message.success('Команда успешно удалена');
    } catch {
      message.error('Ошибка при удалении команды');
    }
  };

  const handleAssignTeamToUser = async (userId, teamId) => {
    try {
      await assignTeamToUser(userId, teamId);
      message.success('Команда успешно назначена пользователю');
      fetchUsersWithoutTeam();
    } catch {
      message.error('Ошибка при назначении команды');
    }
  };

  const handleCreateNewTeam = async (values) => {
    try {
      await addNewTeam(values); // вызываем метод для создания новой команды
      fetchTeams(); // обновляем список команд
      setIsCreateModalVisible(false); // закрываем модальное окно
      message.success('Новая команда успешно создана');
    } catch {
      message.error('Ошибка при создании новой команды');
    }
  };
  

  return (
    <div>
      <h2>Управление командами</h2>

      {/* Кнопка для создания новой команды */}
      <Button 
        type="primary" 
        onClick={() => setIsCreateModalVisible(true)} 
        style={{ marginBottom: 20 }}
      >
        Создать новую команду
      </Button>

      <Table
        dataSource={teams}
        columns={[
          {
            title: 'Название команды',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Действия',
            key: 'actions',
            render: (text, record) => (
              <span>
                <Button onClick={() => handleEditTeam(record)} style={{ marginRight: 10 }}>
                  Редактировать
                </Button>
                <Button danger onClick={() => handleDeleteTeam(record.id)}>
                  Удалить
                </Button>
                <Button onClick={() => fetchUsersInTeam(record.id)}>
                  Посмотреть пользователей
                </Button>
              </span>
            ),
          },
        ]}
        rowKey="id"
      />

      {/* Модальное окно для создания новой команды */}
      <Modal
        title="Создать новую команду"
        visible={isCreateModalVisible}
        onCancel={() => setIsCreateModalVisible(false)}
        footer={null}
      >
        <Form onFinish={handleCreateNewTeam}>
          <Form.Item
            name="name"
            label="Название команды"
            rules={[{ required: true, message: 'Введите название команды' }]}
          >
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Создать команду
          </Button>
        </Form>
      </Modal>

      {/* Модальное окно для редактирования команды */}
      <Modal
        title="Редактировать команду"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          onFinish={(values) => handleUpdateTeam(currentTeam.id, values)}
          initialValues={{ name: currentTeam ? currentTeam.name : '' }}
        >
          <Form.Item
            name="name"
            label="Название команды"
            rules={[{ required: true, message: 'Введите название команды' }]}
          >
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </Form>
      </Modal>

      {isUserListVisible && (
        <div>
          <h3>Пользователи команды</h3>
          <Table
            dataSource={users}
            columns={[
              {
                title: 'Имя пользователя',
                dataIndex: 'username',
                key: 'username',
              },
              {
                title: 'Команда',
                dataIndex: 'teamId',
                key: 'teamId',
                render: (teamId) => {
                  const team = teams.find((team) => team.id === teamId);
                  return team ? team.name : 'Не выбрана';
                },
              },
            ]}
            rowKey="id"
          />
        </div>
      )}
    </div>
  );
};

export default TeamManagement;
